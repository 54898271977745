import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`The CrossFit Open starts today!`}</em></p>
    <p><strong parentName="p">{`Workout 19.1`}</strong></p>
    <p>{`Complete as many rounds as possible in 15 minutes of:\\
19 wall-ball shots (20/14, 10/9′)\\
19-cal. row`}</p>
    <p><strong parentName="p">{`*`}{`We’ll judge during each of our am and noon classes today as well as
tonight during between 4:30-6:30pm.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      